import {
  Box,
  Button,
  chakra,
  Checkbox,
  CheckboxGroup,
  Divider,
  FormLabel,
  HStack,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from '@chakra-ui/react'
import { ChevronDownIcon, FunnelIcon } from '@heroicons/react/24/outline'
import { buildTestId } from 'kach-commons'
import React, { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa'
import { useDebounceSearch } from '../hooks/useDebounceSearch'
import { ITableColumnsController } from '../hooks/useTableColumns'
import { formatAmount } from '../utils/format-amount'
import { ColumnsSearcher } from './ColumnsSearcher'
import {
  EnumOption,
  ICandidateFilter,
  IFilter,
  IFiltersForm,
} from './TableAbstract'
import { TableColumns } from './TableColumns'

const findColumnIcon = (
  candidatesFilters: ICandidateFilter[],
  column: string,
) => candidatesFilters.find((ele) => ele.column === column)?.Icon

const DateFilterComponent: React.FC<{
  registerFilter: UseFormRegister<IFiltersForm>
  index: number
  setFiltersValue: UseFormSetValue<IFiltersForm>
}> = ({ registerFilter, index, setFiltersValue }) => {
  return (
    <Box display='flex' flexDir='column'>
      <Box mb='5'>
        <FormLabel fontSize='xs'>Desde</FormLabel>
        <Input
          size='xs'
          type='date'
          {...registerFilter(`filters.${index}.from`)}
        />
      </Box>
      <Box>
        <FormLabel fontSize='xs'>Hasta</FormLabel>
        <Input
          size='xs'
          type='date'
          {...registerFilter(`filters.${index}.to`)}
        />
      </Box>
    </Box>
  )
}

const ComboboxInput = ({
  search,
  name,
  filterIndex,
  registerFilter,
  setFiltersValue,
  filter,
}: {
  search: NonNullable<IFilter['search']>
  name: string
  filterIndex: number
  registerFilter: UseFormRegister<IFiltersForm>
  setFiltersValue: UseFormSetValue<IFiltersForm>
  filter: IFilter
}) => {
  const { debounceSearch, search: debouncedSearchTerm } = useDebounceSearch()

  const query = useQuery({
    queryKey: [name, debouncedSearchTerm],
    queryFn: (context) => {
      const [_, debouncedSearchTerm] = context.queryKey

      return search(debouncedSearchTerm)
    },
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  const [selectedValues, setSelectedValues] = useState<
    { value: string | number; label: string }[]
  >(() => {
    if (filter?.enums) {
      return filter?.enums
        ?.map((elem) => {
          const selected = query.data?.find((item) => item.value === elem.id)
          if (!selected) return undefined
          return {
            value: selected.value,
            label: selected?.label!,
          }
        })
        .filter(Boolean)
    }

    return []
  })

  return (
    <Stack>
      <Input
        onChange={(e) => debounceSearch(e.target.value)}
        placeholder='Buscar'
        type='text'
        size='sm'
      />
      {query.data &&
        query.data.length > 0 &&
        query.data.map((opt, optIndex) => {
          const selected = selectedValues.find(
            (item) => item.value === opt.value,
          )

          return (
            <Button
              size={'xs'}
              variant='unstyled'
              display={'flex'}
              alignItems={'center'}
              justifyContent='flex-start'
              columnGap={2}
              onClick={() => {
                if (selected) {
                  setSelectedValues(
                    selectedValues.filter((item) => item.value !== opt.value),
                  )
                } else {
                  setSelectedValues([
                    ...selectedValues,
                    { value: opt.value, label: opt.label! },
                  ])
                }
                setFiltersValue(
                  `filters.${filterIndex}.enums.${opt.value}.id`,
                  selected ? null : opt.value,
                )
              }}
            >
              <Icon as={selected ? FaRegCheckSquare : FaRegSquare} />{' '}
              {opt.label}
            </Button>
          )
        })}
      <Divider />
      <Box display='flex' flexDirection='column'>
        <Text fontWeight='semibold' fontSize='sm'>
          Usuarios seleccionados
        </Text>
        {selectedValues?.map((elem) => {
          return <chakra.span fontSize='xs'>{elem.label}</chakra.span>
        })}
      </Box>
    </Stack>
  )
}

const EnumOptionListItem = ({
  value,
  label,
  filterIndex,
  optIndex,
  registerFilter,
  setFiltersValue,
}: {
  value: number
  label: string
  filterIndex: number
  optIndex: number
  registerFilter: UseFormRegister<IFiltersForm>
  setFiltersValue: UseFormSetValue<IFiltersForm>
}) => {
  useEffect(() => {
    setFiltersValue(`filters.${filterIndex}.enums.${optIndex}.id`, value)
  }, [])

  return (
    <Checkbox
      size='sm'
      {...buildTestId(`filter-checkbox-${label}`)}
      {...registerFilter(`filters.${filterIndex}.enums.${optIndex}.checked`)}
    >
      {label}
    </Checkbox>
  )
}

const EnumOptionsList = ({
  options,
  filterIndex,
  registerFilter,
  setFiltersValue,
}: {
  options: EnumOption[]
  filterIndex: number
  registerFilter: UseFormRegister<IFiltersForm>
  setFiltersValue: UseFormSetValue<IFiltersForm>
}) => {
  return (
    <CheckboxGroup colorScheme='brand'>
      <Stack spacing={[2]} direction='column'>
        {options.map((opt, optIndex) => {
          const displayLabel = opt.label || opt.name || opt.value

          return (
            <EnumOptionListItem
              key={opt.value}
              filterIndex={filterIndex}
              label={displayLabel}
              optIndex={optIndex}
              registerFilter={registerFilter}
              setFiltersValue={setFiltersValue}
              value={opt.value}
            />
          )
        })}
      </Stack>
    </CheckboxGroup>
  )
}

export const Filters = ({
  withToggleColumns = true,
  isSsn = false,
  ...props
}: {
  filters: IFilter[]
  filtersObserver: UseFormWatch<IFiltersForm>
  candidatesFilters: ICandidateFilter[]
  registerFilter: UseFormRegister<IFiltersForm>
  onRemoveFilter: (index: number) => void
  onAddFilter: (filter: IFilter) => void
  columnsController?: ITableColumnsController
  setFiltersValue: UseFormSetValue<IFiltersForm>
  withToggleColumns?: boolean
  isSsn?: boolean
}) => {
  return (
    <Box>
      <HStack spacing={'2'}>
        <Box display='flex' alignItems='start'>
          <Box display='flex' alignItems='start' mt='0.1rem'>
            <Tag mb={[2, null, 0]} mr={[0, 2, 2]}>
              <TagLeftIcon as={FunnelIcon} />
              <TagLabel color='gray.600' fontSize='xs'>
                {!props.filters.length
                  ? 'Ningún filtro'
                  : `${props.filters.length} filtros`}
              </TagLabel>
            </Tag>
            <Box display='flex' flexWrap='wrap' flex={[1, 0, '21%']}>
              {props.filters.map((filter, index) => {
                if (filter.disabledRender) {
                  return null
                }

                const IconFilter = findColumnIcon(
                  props.candidatesFilters,
                  filter.column,
                )

                return (
                  <Box key={index}>
                    <Popover isLazy>
                      <PopoverTrigger>
                        <Button
                          leftIcon={IconFilter ? <IconFilter /> : null}
                          variant='outline'
                          size='xs'
                          rightIcon={<Icon as={ChevronDownIcon} />}
                          mr={2}
                          mb={1}
                        >
                          {filter.label}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader fontSize='sm'>
                          Filtrar por{' '}
                          <chakra.span textTransform='lowercase'>
                            {filter.label}
                          </chakra.span>
                        </PopoverHeader>
                        <PopoverBody>
                          <Box display='flex' justifyContent='end' mb={2}>
                            <Button
                              size='xs'
                              onClick={() => props.onRemoveFilter(index)}
                            >
                              Remover filtro
                            </Button>
                          </Box>

                          <Box overflowY={'auto'} maxH={'20rem'}>
                            {(() => {
                              switch (filter.type) {
                                case 'text':
                                  return (
                                    <Input
                                      {...props.registerFilter(
                                        `filters.${index}.value`,
                                      )}
                                      placeholder='Escribe un valor...'
                                      type='text'
                                      size='sm'
                                    />
                                  )

                                case 'range-slider': {
                                  const value = props.filters[index].value || [
                                    0, 0,
                                  ]
                                  return (
                                    <Stack
                                      overflow={'hidden'}
                                      direction={'column'}
                                      spacing={0}
                                    >
                                      <Box
                                        display={'flex'}
                                        w='full'
                                        alignItems={'end'}
                                        justifyContent={'end'}
                                      >
                                        <Text fontSize={'xs'}>
                                          {formatAmount(value[0])} -{' '}
                                          {formatAmount(value[1])}
                                        </Text>
                                      </Box>

                                      <Box display={'flex'} flexDir='column'>
                                        <Box mb='4'>
                                          <FormLabel fontSize='xs'>
                                            Mínimo
                                          </FormLabel>
                                          <Input
                                            onChange={(e) => {
                                              props.setFiltersValue(
                                                `filters.${index}.value`,
                                                [
                                                  parseInt(e.target.value),
                                                  value[1],
                                                ],
                                              )
                                            }}
                                            size='xs'
                                            type='number'
                                          />
                                        </Box>
                                        <Box>
                                          <FormLabel fontSize='xs'>
                                            Máximo
                                          </FormLabel>
                                          <Input
                                            onChange={(e) => {
                                              props.setFiltersValue(
                                                `filters.${index}.value`,
                                                [
                                                  value[0],
                                                  parseInt(e.target.value),
                                                ],
                                              )
                                            }}
                                            size='xs'
                                            type='number'
                                          />
                                        </Box>
                                      </Box>
                                    </Stack>
                                  )
                                }

                                case 'async-enum':
                                  return (
                                    <ComboboxInput
                                      setFiltersValue={props.setFiltersValue}
                                      filterIndex={index}
                                      registerFilter={props.registerFilter}
                                      name={filter.column}
                                      search={filter.search!}
                                      filter={filter}
                                    />
                                  )

                                case 'enum':
                                  const options = filter?.enumData

                                  if (!options) return null

                                  return (
                                    <EnumOptionsList
                                      setFiltersValue={props.setFiltersValue}
                                      filterIndex={index}
                                      options={options}
                                      registerFilter={props.registerFilter}
                                    />
                                  )

                                case 'date':
                                  return (
                                    <DateFilterComponent
                                      registerFilter={props.registerFilter}
                                      index={index}
                                      setFiltersValue={props.setFiltersValue}
                                    />
                                  )
                              }
                            })()}
                          </Box>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Box>
                )
              })}
            </Box>
          </Box>

          {props.filters.length !== props.candidatesFilters.length && (
            <ColumnsSearcher
              key={`columns-searcher-${isSsn}`}
              watch={props.filtersObserver}
              filtersState={props.filters}
              onAddColumn={props.onAddFilter}
              candidatesFilters={props.candidatesFilters}
            />
          )}

          {props?.columnsController && withToggleColumns && (
            <TableColumns columnsController={props.columnsController} />
          )}
        </Box>
      </HStack>
    </Box>
  )
}
